import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumbs, TransitionLink } from '../../components'

const ProfileBreadCrumbs = ({ title }) => {
  return (
    <Breadcrumbs>
      <Breadcrumbs.Item>
        <TransitionLink to="/about-us">About Us</TransitionLink>
      </Breadcrumbs.Item>
      <Breadcrumbs.Item>
        <TransitionLink to="/about-us/team">Our Team</TransitionLink>
      </Breadcrumbs.Item>
      <Breadcrumbs.Item>
        <span>{title}</span>
      </Breadcrumbs.Item>
    </Breadcrumbs>
  )
}

ProfileBreadCrumbs.propTypes = {
  title: PropTypes.string,
}

export default ProfileBreadCrumbs
