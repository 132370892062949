import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import {
  Divider,
  Button,
  PullQuote,
  StatsPin,
  PinBlockGroup,
  Typography,
  MdxWrapper,
  YouTube,
  Breadcrumbs,
  Social,
  PressOfficeWidget,
} from '../../components'

import styles from './TeamProfileTemplate.module.css'

import ContentRightSidebarLayout from '../../components/Layout/ContentRightSidebar/ContentRightSidebar'
import Img from '../../components/gatsbyWrappers/Img'
import ProfileBreadCrumbs from './ProfileBreadCrumbs'

const shortcodes = {
  Breadcrumbs,
  Divider,
  Button,
  PullQuote,
  StatsPin,
  PinBlockGroup,
  YouTube,
  Typography,
  ProfileBreadCrumbs,
}

/**
 * @todo (mdx):
 * Add fields to GraphQL schema
 * export partials that don't use MDX for reuse in CMS previews
 * @todo (mdx):  pass HOC as prop to return MDX renderer so we can reuse templates!
 */

export const TeamProfileTemplate = ({
  title,
  additionalInfo,
  featuredImage,
  editor,
  // bio,
  // pdf,
  // tags
}) => (
  <MDXProvider components={shortcodes}>
    <MdxWrapper>
      <ProfileBreadCrumbs title={title} />
      <Divider thickness={4} />
      <Typography tag="strong" type="boxHeading" className="mb-8">
        Our Team
      </Typography>
      <Typography tag="h1" type="aggregatorIntro" className={styles.title}>
        {title}
      </Typography>
      <Typography tag="strong" type="body">
        {additionalInfo}
      </Typography>
      <div className={styles.profileGrid}>
        <Img src={featuredImage} alt={title} />
        <div className={styles.editor}>
          <MDXRenderer>
            {/* <Img src={featuredImage} alt={title} /> */}
            {editor}
          </MDXRenderer>
        </div>
      </div>
    </MdxWrapper>
  </MDXProvider>
)

TeamProfileTemplate.propTypes = {
  title: PropTypes.string,
  additionalInfo: PropTypes.string,
  featuredImage: PropTypes.string,
  editor: PropTypes.string,
  // pdf: PropTypes.string,
  // tags: PropTypes.string
}

const Article = ({ data }) => {
  const {
    frontmatter: {
      title,
      additionalInfo,
      editor,
      featuredImage,
      // tags
    },
  } = data.mdx

  return (
    <ContentRightSidebarLayout>
      <div>
        <TeamProfileTemplate
          title={title}
          additionalInfo={additionalInfo}
          featuredImage={featuredImage}
          editor={editor}
          // tags={tags}
        />
      </div>
      <div>
        <Social orientation="horizontal" />
        <Divider thickness={4} />
        <PressOfficeWidget />
      </div>
    </ContentRightSidebarLayout>
  )
}

Article.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default Article

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
        additionalInfo
        featuredImage {
          childCloudinaryMediaImage {
            fluid {
              base64
              aspectRatio
              sizes
              srcSet
              src
            }
          }
        }
        editor
      }
    }
  }
`
